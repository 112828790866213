@charset 'utf-8';

@import 'settings';
@import 'foundation';

// Owl Carousel
@import 'node_modules/owl.carousel/src/scss/owl.carousel';
@import 'node_modules/owl.carousel/src/scss/theme.default';

// Photoswipe
@import 'node_modules/photoswipe/src/css/main';
$pswp__assets-path: '/img/photoswipe/';
@import 'node_modules/photoswipe/src/css/default-skin/default-skin';

// Dropzone
@import 'node_modules/dropzone/src/dropzone';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-badge;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-label;
@include foundation-media-object;
@include foundation-pagination;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Dragular
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

$hero-height-small: 200px;
$hero-height-medium: 800px;
$hero-height-large: 800px;

$primary-hover-color: #3f3f43;

html {
    background-color: $primary-color;
}

.no-js {
  @include breakpoint(small only) {
    .top-bar, .mobile-sticky-about-menu {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar, .mobile-sticky-about-menu {
      display: none;
    }
  }
}

.top-padding {
    padding-top: 35px;
}

.small-margin-top { margin-top: 15px }
.medium-margin-top { margin-top: 30px }
.large-margin-top { margin-top: 60px }

@include breakpoint(small only) {
    .padding-top-for-sticky {
        height: 90px !important;
        margin-top: -90px !important;
    }
}

.anchor {
    display: block;
    position: relative;
    top: -56px;
    visibility: hidden;

    @include breakpoint(medium) {
        top: -120px;
    }
}

.anchor-no-sub-header {
    display: block;
    position: relative;
    top: -56px;
    visibility: hidden;

    @include breakpoint(medium) {
        top: -80px;
    }
}

h2 {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 15px 0 15px 0;

    @include breakpoint(medium) {
        margin: 25px 0 25px 0;

        .top-arrow {
            font-size: 0.6em;
        }
    }

    .fa-arrow-up {
        color: $light-gray;
    }

    .fa-arrow-up.dark-arrow {
        color: $medium-gray;
    }

    .top-arrow:hover .fa-arrow-up {
        color: $primary-color;
    }
}

h3 {
    margin-bottom: 30px;
}

h5 {
    font-weight: bold;
}

.header-sticky-container {
    height: 56px;

    @include breakpoint(medium) {
        height: 125px;
    }
}

header {
    width: 100%;
    height: 56px;
    background-color: $primary-color;

    @include breakpoint(medium) {
        height: 90px;
        padding: 20px 0 0 0;
    }

    @include breakpoint(large) {
        height: 125px;
        padding: 30px 0 0 0;
    }
    
    .mobile-logo img {
        height: 40px;
    }

    .menu-open-button {
        cursor: pointer;
        margin-top: 5px;
    }

    .logo {
        transition: all 0.4s ease;
        height: auto;
        margin-top: 40px;

        @include breakpoint(large) {
            height: 75px;
            margin-top: 0;
        }
    }

    .menu {
        transition: all 0.4s ease;
        color: $secondary-color;

        a {
            color: $secondary-color;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 1px;
            height: 80px;
            padding: 34px 20px 6px 20px;
            margin-top: 15px;

            @include breakpoint(large) {
                padding: 34px 30px 6px 30px;
            }
        }

        a.home {
            padding-top: 24px;
        }

        a:hover {
            color: #fff;
            background-color: $primary-hover-color;
        }

        .active a {
            background-color: $primary-hover-color;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
    }

    #mobile-menu .menu {
        background: $secondary-color;
        color: $primary-color;

        a {
            color: $primary-color;
            font-size: 16px;
            margin: 0;
            height: auto;
            padding: 10px 10px 10px 10px;
            border-bottom: 1px solid #cecece;
        }

        a:hover {
            background-color: #cecece;
        }
    }

    #main-menu .menu {
        a:hover {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
    }

    .social-icons {
        transition: all 0.4s ease;

        a {
            padding: 18px 20px 22px 20px;

            @include breakpoint(large) {
                padding: 18px 0 22px 0;
                margin-left: 93px;

                .fa-stack {
                    width: 27px;
                }
            }

            .fa-square {
                color: $secondary-color;
                font-size: 30px;
                margin-top: 6px;
            }

            .fa-facebook {
                color: $primary-color;
                font-size: 18px;
            }
        }

        a:hover {
            background: none;
        }
    }

    .social-icons a:hover .fa-square {
        color: #fff;
    }
}

.compact-header {
    transition: all 0.4s ease;

    @include breakpoint(medium) {
        height: 95px;
        padding: 0;
    }

    @include breakpoint(large) {
        height: 90px;
        padding: 10px 0 0 0;
    }

    .logo {
        height: auto;

        @include breakpoint(large) {
            height: 60px;
            margin-top: 10px;
        }
    }

    .menu {
        a {
            height: 80px;

            @include breakpoint(large) {
                margin-top: 0;
            }
        }
    }
}

.sticky {
    z-index: 2;
}

.sticky-about-menu-container {
    background: #131314;

    .about-menu {
        width: 100%;
        background: #131314;
        line-height: 0;

        @include breakpoint(small only) {
            overflow-y: hidden;
            overflow-x: scroll;
        }

        .menu {
            background: none;

            @include breakpoint(medium) {
                display: inline-block;
            }

            li {
                white-space: nowrap;
            }

            a {
                white-space: nowrap;
                color: #858585;
                padding: 12px 16px 12px 16px;
                font-size: 14px;
                margin: 0;
                height: auto;
                border-bottom: 1px solid #333;

                @include breakpoint(medium) {
                    padding: 18px 30px 18px 30px;
                    border-bottom: none;
                    font-size: 16px;
                }
            }

            a:hover {
                color: #fff;
                background: none;
            }
        }
    }   
}

section.content {
    min-height: 500px;

    @include breakpoint(medium) {
        padding-bottom: 100px;
    }

    p {
        font-size: 16px;
        color: #505050;

        @include breakpoint(medium) {
            font-size: 18px;
        }
    }

    li {
        padding-bottom: 7px;
        font-size: 18px;
        color: #505050;
    }
}

.boxes-margin {
    margin: 40px auto 40px auto;
}

.box {
    padding: 1px !important;

    .image {
        display: block;
        height: 210px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .title {
        display: block;
        color: #fff;
        background-color: $primary-color;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 4px 0 4px 0;
        font-size: 18px;
    }

    a:hover {
        .title {
            background-color: $primary-hover-color;
        }
    }

    .button-area {
        display: block;
        color: #fff;
        text-align: center;
        padding: 6px 0 6px 0;
        font-size: 16px;
    }

    .options {
        border-top: 1px solid #333;

        .primary {background-color: $primary-hover-color}
        .secondary {background-color: $black}
        .delete {background-color: $alert-color}
    }
}

.image-preview-container {
    .image-preview {
        .image {
            margin: 1px;
            height: 390px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}

.hero-container {
    height: $hero-height-small;

    @include breakpoint(medium) {
        height: $hero-height-medium;
    }

    @include breakpoint(large) {
        height: $hero-height-large;
    }

    .hero {
        background-size: cover;
        background-position: top center;
        color: $primary-color;
        height: $hero-height-small;

        @include breakpoint(medium) {
            height: $hero-height-medium;
        }

        @include breakpoint(large) {
            height: $hero-height-large;
        }

        h1 {
            margin-top: 70px;
            font-weight: 300;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 64px;
            }

            @include breakpoint(large) {
                font-size: 72px;
            }
        }
    }

    .owl-dots {
        position: relative;
        top: -50px;
    }

    .owl-nav-buttons {
        position: relative;
        top: -60%;
        z-index: 1;

        .next, .prev {
            background-color: #eee;
            color: $primary-color;
            padding: 8px 10px 8px 10px;
            font-size: 18px;
        }

        .prev {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            padding-right: 14px;
        }

        .next {
            float: right;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            padding-left: 14px;
        }
    }
}

.rope-background {
    padding-bottom: 60px;

    @include breakpoint(large) {
        background-image: url(/img/background-projects.jpg);
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }
}

.contact {
    label {
        margin: 0;
    }

    input, textarea, input:focus, text {
        border: 1px solid $medium-gray;
    }

    .button {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .address p {
        font-size: 20px;
    }

    .recaptcha {
        margin-bottom: 15px;
    }
}

#map {
    height: 500px;
    margin: 40px 0 20px 0;
    border: 2px solid $secondary-color;
}

.team-member {
    margin: 40px 0 40px 0;

    .team-image {
        margin-top: 12px;

        img {
            width: 200px;
        }
    }

    .fa-stack {
        font-size: 14px;
        margin-bottom: 1px;
    }
}

.admin-menu {
    padding: 5px 15px 0 15px;
    margin: 10px 0 0 0;
}

.dropzone {
    margin-bottom: 20px;
    border: 2px dashed $primary-color;
    color: $primary-color;
    font-size: 28px;
}

.input-group-label {
    background: $primary-color;
    color: #fff;
    border: none;
}

.project-logos {
    .project-logo {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.update-logo-error {
    font-size: 12px;
    color: $alert-color;
}

footer {
    background-color: $primary-color;
    height: 170px;
    padding: 34px 0 0 0;
    color: #848484;

    .logo {
        height: 75px;
    }

    p {
        margin-top: 10px;
        font-size: 14px;
    }
}